'use client';

import { createContext, FC, useState, ReactNode, useEffect } from 'react';
import { Modal, ModalProps } from '../Modal';

type SetModalProps = Omit<ModalProps, 'isVisible'>;

type ModalContextType = {
	setModal: (modal: SetModalProps) => void;
	closeModal: () => void;
};

interface ModalProviderProps {
	children: ReactNode;
}

export const ModalContext = createContext<ModalContextType>({ setModal: () => { }, closeModal: () => { } });

export const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
	const [content, setContent] = useState<SetModalProps | null>(null);
	const [isVisible, setIsVisible] = useState<boolean>();

	const handleModal = (modal: SetModalProps) => {
		setContent(modal);
	};

	const handleOnClose = () => {
		setContent(null);
	};

	useEffect(() => {
		window.addEventListener('popstate', handleOnClose);

		return () => {
			window.removeEventListener('popstate', handleOnClose);
		};
	}, []);

	useEffect(() => {
		setIsVisible(!!content);
	}, [content]);

	return (
		<ModalContext.Provider value={{ setModal: handleModal, closeModal: handleOnClose }}>
			{children}

			{content && <Modal {...content} onClose={() => handleOnClose()} isVisible={isVisible} />}
		</ModalContext.Provider>
	);
};
